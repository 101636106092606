import './Form.css'
import Heading from "../../components/Heading/Heading"
import { useState, useEffect } from 'react';

export default function Form(){
	return(
		<div className='form-container' id='contact'>
				<Heading inv>Contact</Heading>
				<ContactForm/>
		</div>
	)
}

export function ContactForm(){
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [ref, setRef] = useState("");
	const [httpStatus, setHttpStatus] = useState("");
	const [httpMsg, setHttpMsg] = useState("");

	const [hidden, setHidden] = useState("");



	const getScroll = (event) => {
		const htmlElement = document.documentElement;
		const percent = htmlElement.scrollTop / htmlElement.scrollTopMax;

		if (percent < 0.75) {
			setHidden(true);
		}else{
			setHidden(false);
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", getScroll);

		return () => {
		  window.removeEventListener("scroll", getScroll);
		};
	  }, []);


	const sendMessage = (event) => {
		event.preventDefault();
		fetch('https://api.kwiatek.xyz/contact', {
			body: JSON.stringify(({name: name, email: email, msg: msg})),
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
			  'content-type': 'application/json'
			},
			method: 'POST',
		}).then( res => {
		  res.headers.forEach(console.log);
		  setHttpStatus(res.status)
			if (!res.ok) {
				res.text().then(text => { 
				  let resMsg = text+" (Correlation ID: "+res.headers.get('x-correlation-id')+")"
				  setHttpMsg(resMsg)

				  throw new Error(res.status +" " + resMsg) 
				}).catch(err => {
				  console.error(err)
				})
				return;
			}  
			ref.reset(); 

		}).catch(err => {
			console.error(err)
		})
	}

	const validateForm = () => {
		return name !== "" && msg !== "" && validateEmail(email)
	}

	const validateEmail = (email) => {
	  return String(email)
		.toLowerCase()
		.match(
		  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	return (
		<div onScroll={getScroll} className={'form-content'+ (hidden ? ' hidden' : '')}>
		<StatusBox status={httpStatus} msg={httpMsg} />
			<form ref={(el) => {setRef(el);}} onSubmit={sendMessage}>
				<FancyInput label='Your Name' set={setName} type="text"/>
				<FancyInput label='Email Address' set={setEmail} type="email"/>
				<FancyTextArea label='Your Message'set={setMsg} />
				<input type="submit" className={'submit-btn'+(validateForm() ? ' ready' : '' )} value='Send' />
			</form>
	  </div>
	)
}

export function FancyInput(props){
  const [notEmpty, setNotEmpty] = useState("");

  const handleChange = (e) => {
	props.set(e.target.value)
	setNotEmpty(e.target.value !== "")
  };

  return (
	<div className="fancy-input">
	  <input className={'box'+(notEmpty ? ' not-empty' : '')} type={props.type} onChange={handleChange} required/>
	  <label>{props.label}</label>
	</div>
  )
}

export function FancyTextArea(props){
  return (
	<div className="fancy-input fancy-area">
	  <textarea className="box" type={props.type} onChange={(e) => props.set(e.target.value)} required/>
	  <label>{props.label}</label>
	</div>
  )
}

export function StatusBox(props){
  if (props.status == 0 ) {
	return;
  }

  if (props.status != 200) {
	return (
	  <div className="status-box status-err">
		<h3>{props.status}</h3>
		<p className="msg">{props.msg}</p>
	  </div>
	)
  }

  return (
	<div className="status-box">
		<h3>Message Sent Successfuly!</h3>
	  <p className="msg">I'll contact you soon.</p>
	</div>
  )
}
