import './About.css'
import Heading from '../../components/Heading/Heading.js'
import Typical from 'react-typical';

export default function About () {
	return (
		<div className='about-container' id='about'>
			<Heading>About me</Heading>
			<div className='about-content h-wrapper'>
				<p>
				I am ambitious and passionate web developer, fascinated in <b>backend</b> technologies. Interested in 
				software development for over 8 years.<br/>
				With a solid foundation in various programming languages and frameworks, 
				I strive to craft robust backend systems that power seamless user experiences.<br/>
				On my daily basis, I actively contribute to a variety of community-driven IT projects, continually expanding my expertise in <b>Backend Development</b>
				, <b>Databases</b>, <b>Infrastructure</b>, <b>Web Development</b>, and more...<br/>

				My unwavering commitment is to consistently deliver exceptional results and ensure your project's success. 
				Let's collaborate and turn your ideas into software that not only functions flawlessly but also captivates users with its elegance and functionality.<br/><br/>

				Feel free to <a href="#contact"><b>Reach Me Out</b></a> to discuss your project; I'm confident that together, we can achieve your goals.<br/>
				I specialize in shorter-term engagements and am eager to bring your vision to life through focused and efficient work, ideal for project-based collaborations rather than full-time positions.<br/>
				</p>
				<Techstack
					stack={[
						{name: 'Go'},
						{name: 'PostgreSQL'},
						{name: 'MongoDB'},
						{name: 'Linux'},
						{name: 'NixOS'},
						{name: 'React.js'},
						{name: 'Docker'},
						{name: 'Kubernetes'},
						{name: 'gRPC'},
						{name: 'Redis'},
						{name: 'RabbitMQ'},
						{name: 'CockroachDB'},
					]}
					more={["Python","Java","SQLite","C#","DNS","Ansible","AWS","Prometheus","Unity3D","C++","SMTP"]}
				/>
			</div>
		</div>
	)
}

export function Techstack(props){
	let more = []
		more.push(800)
	props.more.forEach((name)=>{
		more.push(name)
		more.push(1000)
	})
	more.push("and more...")
	more.push(1200);
	return(
		<div className='techstack-container h-wrapper'>
			<h2>Technologies I used...</h2>
			<div className='techstack-content'>
				<div className='techstack-grid'>
					{props.stack.map(function(tech, i){
						return (
							<div className={'tech'+ (i%2 === 0 ? '' : ' left')}>
								<div className='tech-name'>{tech.name}</div>
							</div>
						);
					})}
				</div>
				<div className='techstack-more'>
					<h3>I also have experience with:</h3>
					<div className="techstack-more-content">
					  <Typical 
						  loop={Infinity}
						  steps={more}
					  />
					</div>
				</div>
			</div>
		</div>
	);
}

