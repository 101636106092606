import './Contact.css'
import './FancyInput.css'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


library.add(faTwitter, faEnvelope,faGithub,faLinkedin);

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

export default function Contact(){
	return (
		<div className='contact-container'>
				<div className='contact-content'>
					<div className='contact-horizontal'>
						<div className='contact-note'>
							<h3>You can also find me here:</h3>
						</div>
						<div className='contact-info'>
							<Social icon={
								<FontAwesomeIcon icon={'fas fa-envelope'} />
							} text="patryk@kwiatek.xyz" url="mailto:patryk@kwiatek.xyz"/>

							<Social icon={
								<FontAwesomeIcon icon={'fab fa-github'} />
							} text="noisersup" url="https://github.com/noisersup"/>

							<Social icon={
								<FontAwesomeIcon icon={'fab fa-linkedin'} />
							} text="Patryk Kwiatek" url="https://linkedin.com/in/noisersup"/>

							<Social icon={
								<FontAwesomeIcon icon={'fab fa-twitter'} />
							} text="@noisersup" url="https://twitter.com/noisersup"/>
						</div>
					</div>
				</div>
		</div>
	)
}


export function Social(props){
	return(
		<div className='contact-social'>
			<div className='social-icon'>{props.icon}</div>
			<a href={props.url}>{props.text}</a>
		</div>
	)
}
