import './Projects.css'
import Heading from '../../components/Heading/Heading.js'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function Projects(){
	return (
		<div className='projects-container'>
			<Heading>Projects</Heading>
			<div className='projects-content'>
					<Project
						title='FerretDB'
						img='/ferretdb.gif'
						tech={["Go","MongoDB","PostgreSQL","SQLite","Docker"]}
						url='https://github.com/FerretDB/FerretDB'>
						I bring a wealth of experience as one of the core developers in a fast-growing Open Source <b>MongoDB</b> alternative<br/>
						FerretDB allows you to use MongoDB drivers seamlessly with <b>PostgreSQL</b>, or <b>SQLite</b> as the database backend. 
						I've been instrumental in implementing core functionalities, <b>optimizing query performance</b>, identifying bugs and security vulnerabilities, and actively engaging with the community to offer support.
						In addition to maintaining essential product components, I've authored blog posts and contributed to documentation.
					</Project>
					<Project
						title='File Storage'
						left
						img='/filestorage.gif'
						tech={["Go","REST API","Redis","Cockroach","React","docker"]}
						url='https://github.com/noisersup/file-storage'>
							An encrypted file storage made in <b>Go</b> with user
							authentication system based on <b>Redis</b> cache.
							Implemented memory optimized <b>multipart
							</b> upload allowing to handle large files.
							Conceptualized and realized a system where all
							encrypted files are stored in one directory and
							whole filetree is recreated and stored in <b>
							Cockroachdb</b>
							<br/><b>React.js</b> frontend
					</Project>
					<Project
						title='Ledyt'
						tech={["Go","API","Reverse-engineering","bubbletea"]}
						img='/ledyt.gif'
						url='https://github.com/noisersup/ledyt'>
						Minimalist CLI youtube client written in <b>Go</b> for
						efficient and lightweight content playback in MPV.<br/>
						Powered by <b>bubbletea</b> framework
					</Project>
					<Project
						title='CHIP-8'
						left
						tech={["Go","Emulation","Binary","OpenGL"]}
						img='/chip8.gif'
						url='https://github.com/noisersup/chip8'>

						An emulator of the CHIP-8 platform made in <b>Go
						</b> with display in <b>OpenGL</b>
					</Project>
					<div className='projects-more-container'>
							<button onClick={()=>{
								window.open("https://github.com/noisersup")
							}}>and more...</button>
					</div>
			</div>
		</div>
	);
}

export function Project(props){
	return (
		<div className={"project-container"+ (props.left ? '' : ' left')}>
			<img src={props.img} alt=""></img>
			<div className='project-content'>
				<div className='project-title-container'>
					<h2 className="project-title">{props.title}</h2>
					<a href={props.url}><FontAwesomeIcon icon={'fab fa-github'} /></a>
				</div>
				<div className='desc-container'>
					<p>{props.children}</p>
				</div>
				<div className='projects-techstack'>
					{props.tech.map(function(tech){
						return (
							<div className='projects-tech'>{tech}</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
