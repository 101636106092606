import './App.css';
import Title from './sections/Title/Title.js';
import About from './sections/About/About.js';
import Projects from './sections/Projects/Projects.js';
import Contact from './sections/Contact/Contact.js';
import Form from './sections/Form/Form';

import Wave from './components/Wave/Wave'

function App() {
  return (
    <div className="App">
      <header className="App-header">
		<Title />
		<Wave color="white" />
		<About />
		<Projects />
		<Wave color="lightgreen"/>
		<Form />
		<Wave color="#282c34"/>
		<Contact />
      </header>
    </div>
  );
}

export default App;

export function KeepScroll(props){
	return(
	  <div className = 'keep-scroll'>{props.children}</div>
	)
}
